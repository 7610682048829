import {
  FetchTaskCacheKey,
  TaskFileAttachmentIds,
  TaskGqlError,
  TaskNanoID,
  TaskUUID,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface SubmitSourceFilesToTaskOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys: FetchTaskCacheKey[];
}

export interface SubmitSourceFilesToTaskResponse<
  SubmitSourceFilesToTaskRecordType
> {
  submitSourceFilesToTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: SubmitSourceFilesToTaskRecordType;
    errors: SubmitSourceFilesToTaskError;
  };
}

export interface SubmitSourceFilesToTaskInput {
  uuid: TaskUUID | TaskNanoID;
  sourceFileIds: TaskFileAttachmentIds;
}

export interface SubmitSourceFilesToTaskError {
  fullMessages: TaskGqlError;
}

const action = 'submitSourceFilesToTask';

function useSubmitSourceFilesToTask<SubmitSourceFilesToTaskRecordType>({
  query,
  cacheKeys
}: SubmitSourceFilesToTaskOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    SubmitSourceFilesToTaskInput,
    SubmitSourceFilesToTaskResponse<SubmitSourceFilesToTaskRecordType>,
    SubmitSourceFilesToTaskError,
    SubmitSourceFilesToTaskRecordType
  >({ action, cacheKeys, query });

  return {
    submitSourceFilesToTaskData: updateQueryData,
    submitSourceFilesToTaskError: updateQueryError,
    submitSourceFilesToTaskLoading: updateQueryLoading,
    submitSourceFilesToTaskErrorMessage: updateQueryErrorMessage,
    submitSourceFilesToTask: updateQuery,
    submitSourceFilesToTaskReset: updateQueryReset
  };
}

export default useSubmitSourceFilesToTask;

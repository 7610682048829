import toNumber from 'lodash/toNumber';
import compact from 'lodash/compact';

import {
  TaskNanoID,
  TaskTrackTimeFields,
  TaskUUID
} from '../../../../../tasksTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { TrackTimeInTaskFormData } from '../../TrackTimeInTaskForm.types';

import { trackTimeInTaskValidationSchema } from './useTrackTimeInTaskForm.schema';

import { useTrackTimeInTask } from '../../../../../hooks/useTrackTimeInTask';

import { TRACK_TIME_IN_TASK_QUERY } from '../../../../../queries/trackTimeInTask';

import { TaskCache } from '../../../../../TaskCache';
import { TrackedTimeIntervalCache } from '../../../../../../trackedTimeIntervals/TrackedTimeIntervalCache';

interface TrackTimeInTaskFormOptions {
  uuid: TaskUUID;
  taskNanoId?: TaskNanoID;
}

const defaultTaskTrackTimeValues: TrackTimeInTaskFormData = {
  body: '',
  minutes: null,
  hours: null
};

function useTrackTimeInTaskForm({
  uuid,
  taskNanoId
}: TrackTimeInTaskFormOptions) {
  const { control, errors, handleSubmitReactHookForm, register, resetForm } =
    useReactHookForm<TrackTimeInTaskFormData>({
      defaultValues: defaultTaskTrackTimeValues,
      isModalForm: true,
      schema: trackTimeInTaskValidationSchema
    });

  const {
    trackTimeInTaskError,
    trackTimeInTaskLoading,
    trackTimeInTaskErrorMessage,
    trackTimeInTask,
    trackTimeInTaskReset
  } = useTrackTimeInTask({
    query: TRACK_TIME_IN_TASK_QUERY,
    cacheKeys: compact<string>([
      TaskCache.indexCacheKey(),
      TrackedTimeIntervalCache.dashboardTrackedTimeIntervalsCacheKey(),
      taskNanoId ? TaskCache.messagesCacheKey(taskNanoId) : null,
      taskNanoId ? TaskCache.taskMembersCacheKey(taskNanoId) : null
    ])
  });

  return {
    validationErrors: {
      minutesValidationError: errors?.minutes?.message,
      hoursValidationError: errors?.hours?.message
    },
    control,
    resetTrackTimeInTaskForm: resetForm,
    trackTimeInTaskError,
    trackTimeInTaskLoading,
    trackTimeInTaskErrorMessage,
    trackTimeInTask,
    trackTimeInTaskReset,
    handleTrackTimeInTask: handleSubmitReactHookForm({
      onSubmit: async (data) =>
        trackTimeInTask({
          ...data,
          hours: data.hours ? toNumber(data.hours) : undefined,
          minutes: data.minutes ? toNumber(data.minutes) : undefined,
          uuid
        })
    }),
    registerFields: {
      registerBody: register(TaskTrackTimeFields.BODY),
      registerHours: register(TaskTrackTimeFields.HOURS),
      registerMinutes: register(TaskTrackTimeFields.MINUTES)
    }
  };
}

export default useTrackTimeInTaskForm;

import { gql } from 'graphql-request';

export const TRACK_TIME_IN_TASK_QUERY = gql`
  mutation TrackTimeInTask(
    $body: String
    $hours: Integer
    $minutes: Integer
    $uuid: ID!
  ) {
    trackTimeInTask(
      input: { body: $body, hours: $hours, minutes: $minutes, uuid: $uuid }
    ) {
      status
      recordUuid
      recordNanoId
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;

import React, { ReactNode } from 'react';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import concat from 'lodash/concat';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { TaskNanoID, TaskProjectNanoID } from '../../../tasksTypes';
import { SubmitSourceFilesToTaskModalButtonInitialFile } from './SubmitSourceFilesToTaskModalButton.types';

import { useSubmitSourceFilesToTaskForm } from '../../forms/SubmitSourceFilesToTaskForm/hooks/useSubmitSourceFilesToTaskForm';
import { SubmitSourceFilesToTaskForm } from '../../forms/SubmitSourceFilesToTaskForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { tasksKeys, words } from '../../../../../locales/keys';
import { convertFileToDropzoneFile } from '../../../../../helpers/dropzone/DropzoneHelper/utils/convertFileToDropzoneFile';

interface SubmitSourceFilesToTaskModalButtonProps {
  taskNanoId: TaskNanoID;
  projectNanoId?: TaskProjectNanoID;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  i18nTitle?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  buttonChildren?: ReactNode;
  initialFile?: SubmitSourceFilesToTaskModalButtonInitialFile;
  initialFiles?: SubmitSourceFilesToTaskModalButtonInitialFile[];
}

const SUBMIT_TASK_SOURCE_FILES_FORM = 'submit-task-source-files-form';

function SubmitSourceFilesToTaskModalButton({
  taskNanoId,
  projectNanoId,
  className,
  icon,
  iconClassName,
  i18nText,
  i18nTitle,
  tooltipI18nText,
  tooltipPlacement,
  buttonChildren,
  initialFile,
  initialFiles
}: SubmitSourceFilesToTaskModalButtonProps) {
  const allInitialFiles = compact(concat(initialFile, initialFiles));
  const allInitialFileIds = map(allInitialFiles, 'id');
  const {
    control,
    resetForm,
    handleSubmitSourceFilesToTask,
    submitSourceFilesToTaskErrorMessage,
    submitSourceFilesToTaskLoading,
    submitSourceFilesToTaskReset,
    validationErrors
  } = useSubmitSourceFilesToTaskForm({
    taskNanoId,
    projectNanoId,
    initialFileIds: allInitialFileIds
  });

  return (
    <FormModalButton
      i18nText={i18nText}
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      form={SUBMIT_TASK_SOURCE_FILES_FORM}
      icon={icon}
      iconClassName={iconClassName}
      i18nSubmitText={words.submit}
      i18nTitle={i18nTitle || tasksKeys.submitSourceFiles}
      isLoading={submitSourceFilesToTaskLoading}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      onOpen={resetForm}
      onClose={submitSourceFilesToTaskReset}
      onSubmit={handleSubmitSourceFilesToTask}
      buttonChildren={buttonChildren}
    >
      <SubmitSourceFilesToTaskForm
        form={SUBMIT_TASK_SOURCE_FILES_FORM}
        control={control}
        isLoading={submitSourceFilesToTaskLoading}
        sourceFileIdsValidationError={
          validationErrors.sourceFileIdsValidationError
        }
        initialFiles={
          isEmpty(allInitialFiles)
            ? {}
            : convertFileToDropzoneFile(allInitialFiles)
        }
        initialFileIds={allInitialFileIds}
      />

      <div className="px-4">
        <AlertMessage message={submitSourceFilesToTaskErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default SubmitSourceFilesToTaskModalButton;

import React, { useMemo } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import map from 'lodash/map';

import {
  GeneralLedgerGeneralLedgerTypes,
  GeneralLedgerID
} from '../../../../../../../../generalLedgers/generalLedgersTypes';
import { CreateSmartContractShareFormData } from '../../../../CreateSmartContractShareForm.types';

import { useTranslate } from '../../../../../../../../../common/hooks/useTranslate';

import { GeneralLedgersMultiSelectFieldControl } from '../../../../../../../../../helpers/FormFields/GeneralLedgersSelectField/components/GeneralLedgersMultiSelectFieldControl';

import { words } from '../../../../../../../../../locales/keys';

interface UserGeneralLedgerSelectFieldGeneralLedger {
  id: GeneralLedgerID;
  generalLedgerType: GeneralLedgerGeneralLedgerTypes;
  name: string;
  defaultShare: number;
}

interface UserGeneralLedgerSelectFieldProps {
  generalLedgers: UserGeneralLedgerSelectFieldGeneralLedger[];
  defaultGeneralLedgerId: GeneralLedgerID;
  control: Control<CreateSmartContractShareFormData>;
  name: FieldPath<CreateSmartContractShareFormData>;
  changeShare: (share: number) => void;
  disabled?: boolean;
}

function UserGeneralLedgerSelectField({
  generalLedgers,
  defaultGeneralLedgerId,
  control,
  name,
  changeShare,
  disabled
}: UserGeneralLedgerSelectFieldProps) {
  const { t } = useTranslate();

  const options = useMemo(() => {
    return map(generalLedgers, (item) => ({
      label:
        item.generalLedgerType === GeneralLedgerGeneralLedgerTypes.DEFAULT
          ? t(words.default)
          : item.name,
      value: item.id
    }));
  }, [generalLedgers, t]);

  const defaultOption = useMemo(() => {
    return options?.find((item) => item.value === defaultGeneralLedgerId);
  }, [defaultGeneralLedgerId, options]);

  return (
    <div className="w-40">
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value, name },
          fieldState: { error: fieldError }
        }) => (
          <GeneralLedgersMultiSelectFieldControl
            data={options}
            disabled={disabled}
            error={fieldError?.message}
            multi={false}
            onChange={(id) => {
              onChange(id);

              const selectedGeneralLedger = generalLedgers?.find(
                (item) => item.id === id
              );
              changeShare(selectedGeneralLedger?.defaultShare || 0);
            }}
            value={value as GeneralLedgerID}
            defaultValue={defaultOption}
            name={name}
            classNamePrefix={fieldError ? 'av_select_error' : 'av_select'}
            inputWrapperClassName="w-full"
            isSearchable
            menuPosition="fixed"
          />
        )}
      />
    </div>
  );
}

export default UserGeneralLedgerSelectField;

import { GeneralLedgerID } from '../../../../generalLedgers/generalLedgersTypes';
import { UserID } from '../../../../users/usersTypes';

interface CreateSmartContractShareFormUser {
  userId: UserID;
  generalLedgerId?: GeneralLedgerID;
  shareType?: string;
  share?: number;
  createdStatus?: CreateSmartContractShareFormShareCreatedStatus;
}

export interface CreateSmartContractShareFormUsers {
  workers: CreateSmartContractShareFormUser[];
  clients: CreateSmartContractShareFormUser[];
}

export interface CreateSmartContractShareFormData {
  users: CreateSmartContractShareFormUsers;
  iteration: number;
}

export enum CreateSmartContractShareFormView {
  SELECT_USERS = 'SELECT_USERS',
  CHANGE_SHARE = 'CHANGE_SHARE'
}

export enum CreateSmartContractShareFormShareCreatedStatus {
  SUCCESS = 'success',
  FAILED = 'failed'
}

import React, { useCallback, useMemo } from 'react';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniqWith from 'lodash/uniqWith';

import { UUID } from '../../../../../../../types';

import { CreateSmartContractShareFormUsers } from '../../CreateSmartContractShareForm.types';
import { CheckableUsersListItemUserType } from '../../../../../../common/modalButtons/InviteUsersModalButton/components/InviteUsersModalBody/components/CheckableUsersListItem';

import { InviteUsersModalBody } from '../../../../../../common/modalButtons/InviteUsersModalButton/components/InviteUsersModalBody';

interface CreateSmartContractShareFormChangeUsersProps {
  invitedUserUuids?: UUID[];
  selectedUsers: CheckableUsersListItemUserType[];
  setSelectedUsers: (selectedUsers: CheckableUsersListItemUserType[]) => void;
  onChange: (users: CreateSmartContractShareFormUsers) => void;
  users: CreateSmartContractShareFormUsers;
}

function CreateSmartContractShareFormChangeUsers({
  onChange,
  users,
  invitedUserUuids,
  selectedUsers,
  setSelectedUsers
}: CreateSmartContractShareFormChangeUsersProps) {
  const filteredUsers = useMemo(() => {
    const usersIds = concat(
      map(users.clients, 'userId'),
      map(users.workers, 'userId')
    );

    return isEmpty(selectedUsers)
      ? []
      : selectedUsers.filter((user) => usersIds.includes(user.id));
  }, [selectedUsers, users.clients, users.workers]);

  const handleDeselectUser = useCallback<
    (selectedUser: CheckableUsersListItemUserType) => void
  >(
    (selectedUser) => {
      const newUsers = filter(filteredUsers, (m) => m.id !== selectedUser.id);

      const clients = filter(newUsers, (user) => user.client);

      const workers = filter(newUsers, (user) => !user.client);

      onChange({
        clients: map(clients, (client) => ({
          userId: client.id,
          share: 0
        })),
        workers: map(workers, (worker) => ({
          userId: worker.id,
          share: 0
        }))
      });

      setSelectedUsers(newUsers);
    },
    [filteredUsers, onChange, setSelectedUsers]
  );

  const handleSelectUser = useCallback<
    (selectedUser: CheckableUsersListItemUserType) => void
  >(
    (selectedUser) => {
      const newUsers = uniqWith(
        [...filteredUsers, selectedUser],
        (prevSelectedUser, m) => prevSelectedUser.id === m.id
      );

      const clients = filter(newUsers, (user) => user.client);

      const workers = filter(newUsers, (user) => !user.client);

      onChange({
        clients: map(clients, (client) => ({
          userId: client.id,
          share: 0
        })),
        workers: map(workers, (worker) => ({
          userId: worker.id,
          share: 0
        }))
      });

      setSelectedUsers(newUsers);
    },
    [filteredUsers, onChange, setSelectedUsers]
  );

  return (
    <InviteUsersModalBody
      invitedUserUuids={invitedUserUuids}
      selectedUsers={filteredUsers}
      selectedUserIds={map(filteredUsers, 'id')}
      onSelectUser={handleSelectUser}
      onDeselectUser={handleDeselectUser}
      withoutCheckAll
    />
  );
}

export default CreateSmartContractShareFormChangeUsers;

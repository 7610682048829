import toNumber from 'lodash/toNumber';
import * as yup from 'yup';

import { formsErrors } from '../../../../../../../locales/keys';

import { TaskTrackTimeFields } from '../../../../../tasksTypes';

export const trackTimeInTaskValidationSchema = yup.object({
  [TaskTrackTimeFields.HOURS]: yup
    .number()
    .transform((_, value) =>
      !value || value === '' ? undefined : toNumber(value)
    )
    .max(7, formsErrors.hours.lessEight)
    .min(0, formsErrors.hours.nonNegative)
    .nullable()
    .when([TaskTrackTimeFields.MINUTES], {
      is: (value: number) => !value,
      then: yup
        .number()
        .transform((_, value) =>
          !value || value === '' ? undefined : toNumber(value)
        )
        .nullable()
        .required(formsErrors.hours.required)
    }),
  [TaskTrackTimeFields.MINUTES]: yup
    .number()
    .transform((_, value) =>
      !value || value === '' ? undefined : toNumber(value)
    )
    .max(59, formsErrors.minutes.lessSixty)
    .min(0, formsErrors.minutes.nonNegative)
    .nullable()
});

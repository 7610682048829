import React from 'react';

import { I18nText } from '../../../../../../../types';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { words } from '../../../../../../../locales/keys';

interface CreateSmartContractShareFormChangeShareHeaderProps {
  i18nText: I18nText;
  goToBack?: () => void;
}

function CreateSmartContractShareFormChangeShareHeader({
  i18nText,
  goToBack
}: CreateSmartContractShareFormChangeShareHeaderProps) {
  return (
    <div className="shrink px-4">
      <div className="flex justify-between items-center border-b border-gray-300 dark:border-gray-700 py-4">
        {goToBack && (
          <div className="-ml-2 -my-2 mr-2">
            <PureTooltipIconButtonHelper
              tooltipI18nText={words.back}
              onClick={goToBack}
              icon={IconsEnum.ARROW_LEFT_OUTLINE}
              iconClassName="w-6 h-6"
              className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            />
          </div>
        )}

        <h3 className="text-base sm:text-lg text-center flex-1 truncate pr-8">
          <Translate id={i18nText} />
        </h3>
      </div>
    </div>
  );
}

export default CreateSmartContractShareFormChangeShareHeader;

import { useMemo } from 'react';

import { FetchTaskGqlQuery, TaskNanoID, TaskUUID } from '../../tasksTypes';

import { FetchTaskCacheKey } from '../../tasksTypes';

import { useShowQuery } from '../../../common/hooks/base/reactQuery/useShowQuery';

interface TaskResponse<ItemType> {
  task: ItemType | null;
}

interface TaskOptions<ItemType> {
  tasks: ItemType[];
  cacheKey: FetchTaskCacheKey;
  query: FetchTaskGqlQuery;
  uuid: TaskUUID | TaskNanoID;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

interface UseTaskItem {
  uuid: TaskUUID;
}

const itemKey = 'task';

function useTask<ItemType extends UseTaskItem>({
  tasks,
  cacheKey,
  query,
  uuid,
  options = {}
}: TaskOptions<ItemType>) {
  const placeholderData = useMemo<TaskResponse<ItemType>>(() => {
    const task = tasks.find((task) => task.uuid === uuid);

    return task ? { task } : null;
  }, [tasks, uuid]);

  const {
    item,
    itemError,
    itemErrorData,
    itemFetched,
    itemFetching,
    itemLoading,
    itemIsPlaceholderData
  } = useShowQuery<TaskResponse<ItemType>, ItemType>({
    cacheKey,
    itemKey,
    query,
    uuid,
    placeholderData,
    options
  });

  return {
    task: item,
    taskErrorData: itemErrorData,
    taskError: itemError,
    taskFetched: itemFetched,
    taskFetching: itemFetching,
    taskLoading: itemLoading,
    taskIsPlaceholderData: itemIsPlaceholderData
  };
}

export default useTask;

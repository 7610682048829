import { createContext, ReactNode, useContext } from 'react';

import {
  ProjectNanoID,
  ProjectTaskName,
  ProjectTaskNanoID,
  ProjectTaskUUID,
  ProjectTeamNanoID,
  ProjectUUID
} from '../../projectsTypes';

type ProjectContextTaskType = {
  name: ProjectTaskName;
  nanoId: ProjectTaskNanoID;
  uuid: ProjectTaskUUID;
};

type ProjectContextTasksType = ProjectContextTaskType[];

interface ProjectContextType {
  companyNanoId: ProjectTeamNanoID;
  projectNanoId: ProjectNanoID;
  projectUuid: ProjectUUID;
  projectTasks: ProjectContextTasksType;
}

const ProjectContext = createContext<ProjectContextType>({
  companyNanoId: null,
  projectNanoId: null,
  projectUuid: null,
  projectTasks: []
});

interface ProjectContextProviderProps {
  children: ReactNode;
  companyNanoId: ProjectTeamNanoID;
  projectNanoId: ProjectNanoID;
  projectUuid: ProjectUUID;
  projectTasks: ProjectContextTasksType;
}

export function ProjectContextProvider({
  children,
  companyNanoId,
  projectNanoId,
  projectUuid,
  projectTasks
}: ProjectContextProviderProps) {
  const projectContextValue = {
    companyNanoId,
    projectNanoId,
    projectUuid,
    projectTasks
  };

  return (
    <ProjectContext.Provider value={projectContextValue}>
      {children}
    </ProjectContext.Provider>
  );
}

export const useProjectContext = () => {
  return useContext(ProjectContext);
};

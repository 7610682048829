import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface SubmitSourceFilesToTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const SUBMIT_SOURCE_FILES_TO_TASK_QUERY = gql`
  mutation SubmitSourceFilesToTask($uuid: ID!, $sourceFileIds: [ID!]!) {
    submitSourceFilesToTask(
      input: { uuid: $uuid, sourceFileIds: $sourceFileIds }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;

import React from 'react';
import { Control, Controller } from 'react-hook-form';

import {
  CreateSmartContractShareFormData,
  CreateSmartContractShareFormShareCreatedStatus
} from '../../../../CreateSmartContractShareForm.types';

import { Icon } from '../../../../../../../../../helpers/Icon';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

interface UserGeneralLedgerCreatedStatusFieldProps {
  control: Control<CreateSmartContractShareFormData>;
  index: number;
  isClient?: boolean;
}

function UserGeneralLedgerCreatedStatusField({
  control,
  index,
  isClient
}: UserGeneralLedgerCreatedStatusFieldProps) {
  return (
    <Controller
      control={control}
      name={
        isClient
          ? `users.clients.${index}.createdStatus`
          : `users.workers.${index}.createdStatus`
      }
      render={({ field: { value } }) =>
        value ? (
          <Icon
            className={
              value === CreateSmartContractShareFormShareCreatedStatus.SUCCESS
                ? 'h-4 w-4 text-green-500'
                : 'h-4 w-4 text-red-500'
            }
            icon={
              value === CreateSmartContractShareFormShareCreatedStatus.SUCCESS
                ? IconsEnum.CHECK
                : IconsEnum.EXCLAMATION_CIRCLE
            }
          />
        ) : null
      }
    />
  );
}

export default UserGeneralLedgerCreatedStatusField;

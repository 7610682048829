import some from 'lodash/some';
import filter from 'lodash/filter';
import every from 'lodash/every';

import { InvoiceTypes } from '../../../invoices/invoicesTypes';
import { InvoiceStatuses } from '../../../invoices/invoicesTypes';

interface DefaultSmartContractItemType {
  invoices: {
    invoiceType: InvoiceTypes;
    status: InvoiceStatuses;
  }[];
}

function getSmartContractIFilteredItems<
  SmartContractItemType extends DefaultSmartContractItemType
>(
  items: SmartContractItemType[],
  withRegularNet?: boolean
): SmartContractItemType[] {
  return filter(
    items,
    (item) =>
      some(
        item.invoices,
        (invoice) =>
          invoice.invoiceType === InvoiceTypes.REGULAR ||
          (withRegularNet && invoice.invoiceType === InvoiceTypes.REGULAR_NET)
      ) && !every(item.invoices, ['status', InvoiceStatuses.VOID])
  );
}

export default getSmartContractIFilteredItems;

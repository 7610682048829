import React, { Fragment } from 'react';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

interface PercentHelperProps {
  percent: number;
}

function PercentHelper({ percent }: PercentHelperProps) {
  if (!isNumber(percent) && !isString(percent)) {
    return null;
  }

  return <Fragment>{percent}%</Fragment>;
}

export default PercentHelper;

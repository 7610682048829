import {
  FetchTaskCacheKey,
  TaskFileAttachmentIds,
  TaskGqlError,
  TaskNanoID,
  TaskUUID,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface SubmitFileAttachmentsAsSourceFilesToTaskOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys: FetchTaskCacheKey[];
}

export interface SubmitFileAttachmentsAsSourceFilesToTaskResponse<
  SubmitFileAttachmentsAsSourceFilesToTask
> {
  submitSourceFilesToTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: SubmitFileAttachmentsAsSourceFilesToTask;
    errors: SubmitFileAttachmentsAsSourceFilesToTaskError;
  };
}

export interface SubmitFileAttachmentsAsSourceFilesToTaskInput {
  uuid: TaskUUID | TaskNanoID;
  fileAttachmentIds: TaskFileAttachmentIds;
}

export interface SubmitFileAttachmentsAsSourceFilesToTaskError {
  fullMessages: TaskGqlError;
}

const action = 'submitFileAttachmentsAsSourceFilesToTask';

function useSubmitFileAttachmentsAsSourceFilesToTask<
  SubmitFileAttachmentsAsSourceFilesToTaskRecordType
>({ query, cacheKeys }: SubmitFileAttachmentsAsSourceFilesToTaskOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    SubmitFileAttachmentsAsSourceFilesToTaskInput,
    SubmitFileAttachmentsAsSourceFilesToTaskResponse<SubmitFileAttachmentsAsSourceFilesToTaskRecordType>,
    SubmitFileAttachmentsAsSourceFilesToTaskError,
    SubmitFileAttachmentsAsSourceFilesToTaskRecordType
  >({ action, cacheKeys, query });

  return {
    submitFileAttachmentsAsSourceFilesToTaskData: updateQueryData,
    submitFileAttachmentsAsSourceFilesToTaskError: updateQueryError,
    submitFileAttachmentsAsSourceFilesToTaskLoading: updateQueryLoading,
    submitFileAttachmentsAsSourceFilesToTaskErrorMessage:
      updateQueryErrorMessage,
    submitFileAttachmentsAsSourceFilesToTask: updateQuery,
    submitFileAttachmentsAsSourceFilesToTaskReset: updateQueryReset
  };
}

export default useSubmitFileAttachmentsAsSourceFilesToTask;

import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';

import { ConvertFileToDropzoneFile } from './convertFileToDropzoneFile.types';
import {
  DropzoneHelperFile,
  DropzoneHelperFiles
} from '../../DropzoneHelper.types';

export default function convertFileToDropzoneFile(
  files: ConvertFileToDropzoneFile[]
) {
  if (isEmpty(files)) {
    return {};
  }

  return keyBy(
    map<ConvertFileToDropzoneFile, DropzoneHelperFile>(files, (file) => ({
      ...file,
      progresses: { 0: 100 },
      state: 'finished',
      activeTab: 'general',
      uploadedId: file.id
    })),
    'id'
  ) as DropzoneHelperFiles;
}

import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';

import { CreateSmartContractShareFormData } from '../../../../CreateSmartContractShareForm.types';

import { InputField } from '../../../../../../../../../helpers/FormFields/InputField';

interface UserGeneralLedgerShareFieldProps {
  control: Control<CreateSmartContractShareFormData>;
  name: FieldPath<CreateSmartContractShareFormData>;
  disabled?: boolean;
}

function UserGeneralLedgerShareField({
  control,
  name,
  disabled
}: UserGeneralLedgerShareFieldProps) {
  return (
    <div className="w-16 flex gap-2 relative text-sm items-center">
      <Controller
        control={control}
        name={name}
        rules={{
          validate: (value) => {
            if (!value || +value < 0 || +value > 100) {
              return ' ';
            }

            return true;
          }
        }}
        render={({
          field: { onChange, value, name },
          fieldState: { error: fieldError }
        }) => {
          return (
            <InputField
              disabled={disabled}
              error={fieldError?.message}
              inputClassName="border-0 border-b border-gray-300 dark:border-gray-700 p-0 focus:ring-0 focus:border-blue-500 w-full dark:focus:placeholder-gray-500 placeholder-gray-400 focus:placeholder-gray-300 text-sm bg-transparent"
              min={0}
              max={100}
              name={name}
              onChange={onChange}
              value={value?.toString()}
              showErrorIcon={false}
              step="any"
              type="number"
            />
          );
        }}
      />

      <span> % </span>
    </div>
  );
}

export default UserGeneralLedgerShareField;

import React from 'react';

import { ErrorMessage, IsLoading } from '../../../../../types';
import { SubmitSourceFilesToTaskFormData } from './SubmitSourceFilesToTaskForm.types';

import { Form } from '../../../../../helpers/Form';
import {
  DropzoneField,
  DropzoneFieldRequiredProps
} from '../../../../../helpers/FormFields/DropzoneField';
import {
  DropzoneHelperFileIds,
  DropzoneHelperFiles
} from '../../../../../helpers/dropzone/DropzoneHelper';

import { TaskFields } from '../../../tasksTypes';

interface SubmitSourceFilesToTaskFormProps {
  form: string;
  isLoading?: IsLoading;
  sourceFileIdsValidationError: ErrorMessage;
  initialFiles?: DropzoneHelperFiles;
  initialFileIds?: DropzoneHelperFileIds;
}

function SubmitSourceFilesToTaskForm({
  form,
  control,
  isLoading,
  sourceFileIdsValidationError,
  initialFiles,
  initialFileIds
}: SubmitSourceFilesToTaskFormProps &
  DropzoneFieldRequiredProps<SubmitSourceFilesToTaskFormData>) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <DropzoneField
          withoutTabs
          control={control}
          name={TaskFields.SOURCE_FILE_IDS}
          error={sourceFileIdsValidationError}
          type="sourceFiles"
          labelFor="submit-task-source-files"
          disabled={isLoading}
          initialFiles={initialFiles}
          initialFileIds={initialFileIds}
        />
      </div>
    </Form>
  );
}

export default SubmitSourceFilesToTaskForm;

import { UserNanoID, UserUUID } from '../users/usersTypes';

export class TrackedTimeIntervalCache {
  static userTrackedTimeIntervalsTodayCacheKey(id: UserUUID | UserNanoID) {
    return `tracked-time-interval-today-${id}`;
  }

  static dashboardTrackedTimeIntervalsCacheKey() {
    return 'tracked-time-intervals-dashboard';
  }
}

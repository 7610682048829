import {
  TrackTimeInTaskGqlQuery,
  CreateTaskGqlStatus,
  FetchTasksCacheKey,
  TaskGqlError,
  TaskUUID,
  TaskNanoID
} from '../../tasksTypes';

import {
  MessageBody,
  MessageHours,
  MessageMinutes
} from '../../../messages/messagesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface TrackTimeInTaskOptions {
  query: TrackTimeInTaskGqlQuery;
  cacheKeys?: FetchTasksCacheKey[];
}

interface TrackTimeInTaskRecordResponse {
  uuid: TaskUUID;
}

interface TrackTimeInTaskResponse {
  trackTimeInTask: {
    status: CreateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: TrackTimeInTaskRecordResponse;
    errors: TrackTimeInTaskError;
  };
}

interface TrackTimeInTaskInput {
  body: MessageBody;
  hours: MessageHours;
  minutes: MessageMinutes;
  uuid: TaskUUID;
}

interface TrackTimeInTaskError {
  fullMessages: TaskGqlError;
}

const action = 'trackTimeInTask';

function useTrackTimeInTask({ query, cacheKeys }: TrackTimeInTaskOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    TrackTimeInTaskInput,
    TrackTimeInTaskResponse,
    TrackTimeInTaskError,
    TrackTimeInTaskRecordResponse
  >({ action, cacheKeys, query });

  return {
    trackTimeInTaskData: createQueryData,
    trackTimeInTaskError: createQueryError,
    trackTimeInTaskLoading: createQueryLoading,
    trackTimeInTaskErrorMessage: createQueryErrorMessage,
    trackTimeInTask: createQuery,
    trackTimeInTaskReset: createQueryReset
  };
}

export default useTrackTimeInTask;
